import React, {useState, useRef, useEffect}  from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Environment, OrbitControls, Scroll, ScrollControls,  TorusKnot} from '@react-three/drei';
import BuildingsModel from './BuildingsModel';
import { getProject, val } from '@theatre/core';
import { PerspectiveCamera, SheetProvider, editable as e, useCurrentSheet } from '@theatre/r3f';
import JsonState from '../demoAnimation.json';
import JsonMobilAnimation from '../animationMobil.json'
import ContactMe from '../components/ContactMe';
import { useScroll } from '@react-three/drei';
import PageTwo from '../components2/PageTwo';
import PageOne from '../components2/PageOne';
import PageThree from '../components2/PageThree';
import RealPageTwo from '../components2/RealPageTwo'
import PageFive from '../components2/PageFive';
import ProjectsDisplayMobile from '../components/ProjectsDisplayMobile';

const demoSheet = getProject('demo', {state: JsonState }).sheet('sheetDemo', 'landscape')
const demoSheetMobile = getProject('demoMobile', {state: JsonMobilAnimation }).sheet('sheetDemo', 'portait')




export default function CanvasElement(props){
    const [orientation, setOrientation] = useState('landscape')
    const canvasRef = useRef(null)
    const pageTwoRef = useRef(null)

    const isPortrait = () => {
       if(window.matchMedia('(orientation:portrait)').matches){
        return true
       }
        else return false
    }


    

    useEffect(()=>{
        window.addEventListener('orientationchange', ()=>{
            window.location.replace('/')
        })
        
        if(isPortrait()){
            return setOrientation('portrait');
         }
    }, [])

    



    return (
        <Canvas ref={canvasRef} style={{zIndex:10, backgroundColor:'var(--background-gray)'}} gl={{preserveDrawingBuffer:true}}>
            <Environment background={false} files='indoorLights.hdr'/>
            <ScrollControls pages={5}>
                <Scroll>
                    <SheetProvider sheet={orientation === 'landscape' ? demoSheet : demoSheetMobile}>
                        <Scene/>
                    </SheetProvider> 

                </Scroll>
 
                <Scroll html>
                    <div>
                        <PageOne/>
                    </div>
                    <div ref={pageTwoRef} style={{position:'fixed', zIndex:11, height:'100vh',padding: '0 2rem' ,display:'flex' ,top:'100vh',  justifyContent:'center',}}>
                        <RealPageTwo/>
                    </div>
                    <div className='scroll-page-container' style={{width:'100vw', height:'100vh',  position:'fixed', top:'200vh', padding:'4rem'}}>
                        <div style={{width:'100%', height:'100%',}}>
                            <PageTwo/>
                        </div>
                    </div>
                    <div className='scroll-page-container' style={{position:'fixed', top:'300vh', width:'100vw', height:'100vh', padding:'4rem'}}>
                        {orientation === 'landscape' ? <PageThree /> : <ProjectsDisplayMobile/>}
                    </div>
                    <div 
                        className='scroll-page-container page-five'
                        style={{
                            position:'fixed',
                            top:'400vh',
                            height:'100vh',
                            width:'50vw',
                            padding:'4rem',
                            display:'grid'
                            }}>
                        <PageFive/>
                    </div>
                </Scroll>
            </ScrollControls>
        </Canvas>
    )
}

const Scene = (props) => {
    const sheet = useCurrentSheet();
    const scroll = useScroll();
    const torusRef = useRef(null)

    const rotateTorus = () =>{
        if(torusRef.current) {
            torusRef.current.rotation.z += 0.012;
        }
    }

    useFrame(() => {
        //rotate torus
        rotateTorus()
        const sequenceLength = val(sheet.sequence.pointer.length);
        sheet.sequence.position = scroll.offset * sequenceLength;
    })

    return (
        <>
            <PerspectiveCamera 
                makeDefault
                theatreKey='camera'
                fov={60}
                near={0.1}
                far={2500}
                 />
                <e.group rotateOnAxis={.78} theatreKey='torus'>
                    <TorusKnot ref={torusRef} rotation={[.5,0,0]} args={[1, 0.11, 1024, 96, 6, 11]}>
                        <meshStandardMaterial envMapIntensity={1} metalness={.7} roughness={.14} color={'#98ee51'} />
                    </TorusKnot>
                </e.group>     
            <BuildingsModel/>
        </>
    )
}