import React, {useState} from 'react';

export default function PagesDisplay(props){
    const {totalPages,customClass, startIndex} = props
    const pages= []
    for(let i = 0; i < totalPages; i++ ){
        pages.push(<div className={`page-circle ${customClass} ${i=== startIndex ? 'currentPage' : null}`} key={i}/>)
    }
    console.log(pages)
    return (
        <div className={`pages-display-container ${customClass}`}>
                {pages.map((page, i) => {
                    return page
                })}
            
        </div>
    )
}

