import { useEffect, useState } from "react";
import {CgMenu} from 'react-icons/cg'
import {AiOutlineClose} from 'react-icons/ai'
import { useNavigate } from "react-router-dom";


export default function TopBar(props){
    const [isOpen, setIsOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState('/')
    const navigate = useNavigate()
    const isPortrait = ()=> {
        if(window.matchMedia('(orientation: portrait)').matches){
            return true;
        } else return false;
    }



    useEffect(()=>{
        const path = window.location.pathname
        console.log(path)
        if(path === '/'){
            return
        }
        else{
            let pathString = path.split('/')[1].toString()
            console.log(pathString)
            return setCurrentPage(pathString)
        }
    })


    if(isPortrait()){
        if(isOpen){
            return (
                <nav className={`header-bar ${isOpen}`}>
                    <h1 style={{color:'#949494'}}>Menu</h1>
                    <div style={{position:'absolute', right:'0'}}>
                        <button className="menu-icon" onClick={()=>setIsOpen(!isOpen)} style={{fontSize:'1.15rem', backgroundImage:'none',color:'var(--page-blue)' ,  backgroundColor:'transparent'}}><AiOutlineClose/></button>
                    </div>
                    <ul className="ul-mobile">
                        <li> <button  className={`${currentPage === '/' ? 'current' : null}`} onClick={()=> {
                        setIsOpen(false)    
                        setCurrentPage('/')
                        navigate('/')
                        }}>Home</button> </li>

                        <li> <button  className={`${currentPage === 'contact' ? 'current' : null}`}   onClick={()=> {
                        setIsOpen(false)    
                        setCurrentPage('contact')
                        navigate('contact')}}>Contact</button> </li>

                        <li> <button  className={`${currentPage === 'projects' ? 'current' : null}`} onClick={()=> {
                        setIsOpen(false)    
                        setCurrentPage('projects')
                        navigate('projects')}}>Projects</button> </li>


                        <li> <button  className={`${currentPage === '404' ? 'current' : null}`} onClick={()=> {
                        setIsOpen(false)    
                        setCurrentPage('404')
                        navigate('404')}}>404</button> </li>
                    </ul>
                </nav>
                )
        }

        else{
            return (
                <button onClick={()=>setIsOpen(!isOpen)} style={{position:'fixed', padding:'1.5rem', zIndex:100, right: 0, fontSize:'1.6rem', backgroundColor:'transparent', backgroundImage:'none'}}>
                    <CgMenu style={{ color:'white'}}/>
                </button>
            )
        }
    }

    else {
        return (
            <nav style={{color:'white', position:'fixed', width:'100%', }}>
                <ul style={{display:'flex', justifyContent:'flex-end', gap:'2rem', padding:'1rem', paddingRight:'3rem', fontSize:'.70em', color:'white' }}>
                    <li><button className={`${currentPage === '/' ? 'current' : null}`} onClick={()=> {
                        setIsOpen(false)    
                        setCurrentPage('/')
                        navigate('/')
                        }}>
                            Home</button></li>

                    <li><button className={`${currentPage === 'contact' ? 'current' : null}`} onClick={()=> {
                        // document.querySelectorAll('.has-linear-gradient').forEach(element => element.style.backgroundImage = 'linear-gradient(122deg,#29bbd2,#303fad)')
                        // document.querySelector('.current').style.backgroundImage = 'linear-gradient(122deg,#29bbd2,#303fad)'
                        setIsOpen(false)    
                        setCurrentPage('contact')
                        navigate('contact')}}>
                            Contact</button></li>

                    <li><button className={`${currentPage === 'projects' ? 'current' : null}`} onClick={()=> {
                        setIsOpen(false)  
                        setCurrentPage('projects')
                        navigate('projects')}}>
                            Projects</button></li>
                            <li> <button  className={`${currentPage === '404' ? 'current' : null}`} onClick={()=> {
                        setIsOpen(false)    
                        setCurrentPage('404')
                        navigate('404')}}>
                            404</button> </li>
                </ul>
            </nav>
        )
    }
}
