import React, { useEffect, useRef, useState } from 'react';

export default function RealPageTwo() {
    const pageTwoContent = useRef(null)
    const [visible, setVisible] = useState(false)
    const containerRef = useRef(null)

       //observe when element intersects viewport.
       const observer = new IntersectionObserver(entries => {
        const { intersectionRatio, isIntersecting, intersectionRect} = entries[0];
        if(intersectionRatio >= .5){
            setVisible(true)
        }
        else {
            setVisible(false)
        }
    }, { threshold: [.1,.5, 1]})


    useEffect(() => {
        if (pageTwoContent.current) {
            observer.observe(pageTwoContent.current)
        }
    })

    return (
        <div ref={containerRef}style={{ maxWidth: '100%', overflow: 'hidden'}}>
            <h1 
            ref={pageTwoContent}
                className={`${visible ? 'visible' : null} page-two-title`}
                style={{
                    color: 'var(--text-white)',
                    fontSize: '2.5rem',
                    zIndex: 11,
                    whiteSpace: 'nowrap',
                    opacity: 0,
                    transition: 'opacity .35s',
                }}>
                I Build Full-Stack <br />  Web <span className='symbol-green'>&</span> Mobile <br /> Applications</h1>
        </div>
    )
}

