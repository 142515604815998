import React, { useEffect, useState, useRef } from 'react';
import { db } from "../FirebaseApp"
import { getDocs, collection } from "firebase/firestore"
import PagesDisplay from '../components/PagesDisplay';

export default function ProjectsDisplayMobile(){
    const [isVisible, setIsVisible] = useState(false)

    return (
        <div className='page-three-container'>
            <h1 className={isVisible ? 'visible' : null} style={{paddingLeft:'1rem', opacity:0, transition:'opacity .35s'}}>Projects</h1>
            <ProjectsContainer setHeaderVisibility={setIsVisible}/>
        </div>
    )
    
}

//
//



const ProjectsContainer= ({setHeaderVisibility}) => {
    const [projectsList, setProjectsList] = useState(null)
    const projectsCollectionRef = collection(db, 'projects');
    const [currentIndex, setCurrentIndex] = useState(0)
    const [offsetX, setOffsetX] = useState(0)
    const [touchStart, setTouchStart] = useState(0);
    const projectsContainerRef = useRef(null)
    const [visible, setVisible] = useState(false)

    //observe when element intersects viewport.
    const observer = new IntersectionObserver(entries => {
        const { intersectionRatio, isIntersecting, intersectionRect} = entries[0];
        console.log(entries.intersectionRatio)
        if(intersectionRatio >= .15){
            setHeaderVisibility(true)
            setVisible(true)
        }
        else {
            setHeaderVisibility(false)
            setVisible(false)
        }
    }, { threshold: [.1, .20, .5, 1]})


    useEffect(() => {
        if (projectsContainerRef.current ) {
            observer.observe(projectsContainerRef.current)
        }
    })

    const handleTouchStart = (e)=> {
        console.log(projectsContainerRef.current.style.transform)
        const touchX = e.changedTouches[0].clientX;
        setTouchStart(touchX);
    };
    
    const handleTouchEnd = (e) => {
        const lastTouchX= e.changedTouches[0].clientX
        calculateShouldSwipe(touchStart, lastTouchX)

    }

    const calculateShouldSwipe = (touchStart, touchEnd) => {
        const difference = touchStart - touchEnd;
        const screenWidth = window.screen.availWidth;
        console.log(difference)
        if(difference > 80 && currentIndex !== 1 ){
            console.log('difference > than 80')
            setOffsetX(offsetX - screenWidth)
            setCurrentIndex(currentIndex + 1)
        }
        else if(difference < -80 && currentIndex !== 0){
            projectsContainerRef.current.style.transform = `translateX(${window.screen.availWidth}px)`
            const screenWidth = window.screen.availWidth;
            setOffsetX(offsetX + screenWidth)
            return setCurrentIndex(currentIndex - 1)
        }
        else return 
    }

    async function getProjects(){
        console.log('fetchDB')
        let allProjects = [];
        let result = await getDocs(projectsCollectionRef)
        result.forEach(doc => {
            let data = doc.data()
            allProjects.push(data);
        })
    setProjectsList(allProjects);
    }

    useEffect(() =>{
        getProjects()
    }, [])

    if(projectsList){
        return (
            <>
                <div 
                    onTouchStart={(e)=>{handleTouchStart(e)}}
                    onTouchEnd={(e)=>handleTouchEnd(e)}
                    ref={projectsContainerRef}
                    style={{transform:`translateX(${offsetX}px)`, width:'200vw', display:'grid', gridTemplateColumns:`repeat(${2}, 1fr)`, opacity:0}}
                    className={`projects-container ${visible ? 'visible' : null}`
                    }>
                    {projectsList.map((project, index) => {
                    if(project.projectName === 'Cover Collab' || project.projectName === 'Drei Wallets'){
                        return (
                                <div  className='project-card' key={index} style={{border:'.01rem solid var(--border-thin-white)',padding:'1rem'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
                                        <div>
                                            <h2 style={{padding: 0, paddingBottom:'.5rem'}}>{project.projectName}</h2>
                                            {/* <a className='project-url' href={`${project.liveUrl}`}>Live View</a> */}
                                        </div>
                                        <button onClick={()=>{window.location.replace(project.liveUrl)}} style={{padding:'.5rem', fontSize:'.7rem', color:'var(--background-gray)'}}>View Project</button>
                                    </div>
                                    <p>{project.description}</p>
                                    <div style={{width:'100%', height:'100%', position:'relative', display:'grid', alignItems:'center'}}>
                                        <img style={{maxWidth:'100%'}} src={project.projectImages[1]}/>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                <PagesDisplay startIndex={currentIndex} totalPages={2} customClass='projects-page-display'/>
            </>

        )
    }
}
