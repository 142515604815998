import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "typeface-roboto"
import "typeface-nunito"
import "typeface-shadows-into-light"
import "typeface-montserrat"
import Router from './components/Router';
import Loader from './components/Loader';

//Not Needed for production, initiates studio for Theatre.js
// 
// import studio from '@theatre/studio';
// import extension from '@theatre/r3f/dist/extension';
// studio.extend(extension)
// studio.initialize();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader/>}>
        <Router/>
    </Suspense>
 </React.StrictMode>
);

