import React, {useEffect, useRef, useState} from 'react';
import { useLoader } from "@react-three/fiber";
import { GLTFLoader,  } from "three/examples/jsm/loaders/GLTFLoader";

export default function BuildingsModel(props){
    const modelRef = useRef(null)
    const gltf = useLoader(GLTFLoader, '/3dModels/earth.glb')
    const scaleFactor = 1.8;

    useEffect( () => {
        if(modelRef.current){
            modelRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor)
        }   
    })

    return <primitive ref={modelRef} object={gltf.scene}/>
}