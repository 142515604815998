import React, { useEffect, useRef, useState } from 'react';
import { db } from "../FirebaseApp"
import { getDocs, collection } from "firebase/firestore"
import PagesDisplay from '../components/PagesDisplay';

export default function PageThree(){
    const [isVisible, setIsVisible] = useState(false)
    return (
        <div className='page-three-container'>
            <h1 className={isVisible ? 'visible' : null} style={{paddingLeft:'1rem', opacity:0, transition:'opacity .35s'}}>Projects</h1>
            <ProjectsContainer setHeaderVisibility={setIsVisible}/>
        </div>
    )
}
//
//


const ProjectsContainer= ({setHeaderVisibility}) => {
    const [projectsList, setProjectsList] = useState(null)
    const projectsCollectionRef = collection(db, 'projects');
    const projectsContainerRef = useRef(null)
    const [visible, setVisible] = useState(false)

    //observe when element intersects viewport.
    const observer = new IntersectionObserver(entries => {
        const { intersectionRatio, isIntersecting, intersectionRect} = entries[0];
        console.log(entries[0].intersectionRatio)
        if(intersectionRatio >= .5){
            setHeaderVisibility(true)
            setVisible(true)
        }
        else {
            setHeaderVisibility(false)
            setVisible(false)
        }
    }, { threshold: [.1, .20, .5, 1]})

    useEffect(() => {
        console.log(projectsList)
        if (projectsContainerRef.current ) {
            observer.observe(projectsContainerRef.current)
        }
    })


    async function getProjects(){
        console.log('fetchDB')
        let allProjects = [];
        let result = await getDocs(projectsCollectionRef)
        result.forEach(doc => {
            let data = doc.data()
            allProjects.push(data);
        })
    setProjectsList(allProjects);
    }

    useEffect(() =>{
        getProjects()
    }, [])


    if(projectsList){
        return (
            <div ref={projectsContainerRef} className={`projects-container ${visible ? 'visible' : null}`}>
                {projectsList.map((project, index) => {
                    if(project.projectName === 'Cover Collab' || project.projectName === 'Drei Wallets'){
                        console.log(project)
                        return (
                            <div  className='project-card' key={index} style={{border:'.01rem solid var(--border-thin-white)', padding:'1rem'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
                                    <div>
                                        <h2 style={{padding: 0, paddingBottom:'.5rem'}}>{project.projectName}</h2>
                                    </div>

                                    <button onClick={()=>{window.location.replace(project.liveUrl)}} style={{padding:'.5rem', fontSize:'.7rem', color:'var(--background-gray)'}}>View Project</button>
                                </div>
                                <p>{project.description}</p>
                                <div style={{width:'100%', height:'100%', position:'relative', display:'grid', alignItems:'center'}}>
                                     <img style={{maxWidth:'100%', borderRadius:'.5rem', alignSelf:'flex-end'}} src={project.projectImages[1]}/>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }
}

