import { Canvas, useFrame } from '@react-three/fiber';
import React, { useRef } from 'react';
import ContactMe from './ContactMe';
import EarthModel from '../3dComponents/EarthModel'
import { editable as e, PerspectiveCamera, SheetProvider } from '@theatre/r3f';
import { getProject } from '@theatre/core';
import { Environment, OrbitControls } from '@react-three/drei';
import JsonEarthState from '../earthGui.json'
import Stars from '../3dComponents/Stars';
import { NoToneMapping } from 'three';
// import {GoLinkExternal} from 'react-icons/go';
import {RxExternalLink} from 'react-icons/rx'

const earthGui = getProject('earthGUI', { state: JsonEarthState }).sheet('EGui')

export default function ContactMePage() {
    const modelRef = useRef(null)



    return (
        <div className='contact-me-page'>
            <div className='contact-content' style={{ position: 'fixed', zIndex: 20 }}>
                <div className='contact-left'>
                    <h1 className='contact-title' style={{fontSize:'4rem', paddingLeft:'2rem', alignSelf:'flex-start'}}>Contact</h1>
                    <div style={{ display: 'grid', paddingLeft: '2rem', gap:'.5em'  }}>
                        <p className='email-text'>Send me an email<br /> <span className='symbol-green'>migijc@gmail.com</span></p>
                        <a className='link' href='https://www.linkedin.com/in/miguel-gil-a7169316b' style={{ backgroundImage: 'none', color: 'var(--subtitle-gray)', WebkitTextFillColor: 'initial', fontWeight: 500, display:'flex', alignItems:'center' }}>LinkedIn <RxExternalLink/></a>
                        <a className='link' href='https://github.com/migijc' style={{ backgroundImage: 'none', color: 'var(--subtitle-gray)', WebkitTextFillColor: 'initial', fontWeight: 500, display:'flex', alignItems:'center' }}>Github <RxExternalLink/></a>
                    </div>

                    <ContactMe />
                </div>
                {/* <div >
                    he
                </div> */}
            </div>

            <Canvas style={{ zIndex: 1 }}>
                {/* <OrbitControls enablePan enableRotate enableZoom/> */}
                {/* <Environment files='indoorLights.hdr'/> */}
                <SheetProvider sheet={earthGui}>
                    <e.directionalLight theatreKey='spot' />
                    <Scene />
                </SheetProvider>
            </Canvas>
        </div>
    )
}

const Scene = () => {
    const modelRef = useRef(null)

    const rotateEarth = () => {
        modelRef.current.rotation.y += 0.0002
    }

    useFrame(() => {
        if (modelRef.current) {
            rotateEarth()
        }
    })

    return (
        <>
            <PerspectiveCamera lookAt={modelRef.current} makeDefault zoom={1} far={1500} position={[-900, 0, 1600]} theatreKey='cam' />
            <e.group ref={modelRef} theatreKey='world'>
                <EarthModel />
            </e.group>
            <e.group theatreKey='stars'>
                <Stars />
            </e.group>

        </>

    )
}