import React from "react";
import ContactForm from './ContactForm'

export default function ContactMe() {

    return (
        <div className="contact-me">
            <h1>Let<span className="symbol-green ">'</span>s Connect</h1>
            <div className="contact-content-container">
                <p>Looking for a developer to help bring your project to life?
                       Don't hesitate to reach out and let's discuss how I can help you achieve your goals.
                </p>
                <ContactForm/>
            </div>

        </div>
    )
}