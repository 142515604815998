import { db } from "../FirebaseApp"
import { getDocs, collection } from "firebase/firestore"
import { useEffect, useState } from "react";


export default function ProjectsContainer(){
    const [projectsList, setProjectsList] = useState(null);

    const projectsCollectionRef = collection(db, 'projects');
    async function getProjects(){
        console.log('fetchDB')
        let allProjects = [];
        let result = await getDocs(projectsCollectionRef)
        result.forEach(doc => {
            let data = doc.data()
            allProjects.push(data);
        })
    setProjectsList(allProjects);
    }
    useEffect(() => {
        getProjects()
    }, [])


    if(projectsList){
      return (
        <div className="projects-page-container" style={{padding:'4rem', maxWidth:'100vw', overflowX:'hidden', gap:'1rem', backgroundColor:'var(--background-gray)'}}>
            <h1>Projects</h1>
            <div className="projects-page-projects" style={{display:'grid', gridTemplateColumns:'1fr 1fr', gap:'2rem'}}>
                {projectsList.map((project)=>{
                    return (
                            <div style={{display:'grid',alignItems:'center',borderRadius:'.5rem', gridTemplateRows:'1fr 2fr 4fr',border:'.01rem solid var(--border-thin-white)',gap:'1rem', padding:'1rem', height:'100%' }}>
                                <div>
                                    <h2>{project.projectName}</h2>
                                </div>

                                <div>
                                    <p>{project.description}</p>
                                </div>

                                <div style={{position:'relative' , width:'100%', height:'100%'}}>
                                    <div style={{ width:'100%', height:'100%',backgroundPosition:'center', backgroundImage: `url(${project.projectImages[1]})`, backgroundSize:'contain', backgroundRepeat:'no-repeat'}} />
                                </div>
                            </div>
                    )
                })}
            </div>
        </div>

      )
    }
}