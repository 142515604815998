import React from 'react';

export default function Loader(){

    return (
        <div style={{width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#151515', flexDirection: 'column', gap:'1.5rem'}}>
            <span className="loader"></span>
            <p style={{color:'white'}}>Loading 3D Model</p>
        </div>
    ) 
}