import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "../3dComponents/PageNotFound";
import ThreeDCanvasContainer from "../components/ThreeDCanvasContainer";
import TopBar from './TopBar'
import ContactMePage from './ContactMePage'
import ProjectsContainer from '../components/ProjectsContainer'

export default function Router(){
    return(
        <BrowserRouter>
            <TopBar/>
            <Routes>
                <Route path="*" element={<PageNotFound/>}/>
                <Route path="/" element={<ThreeDCanvasContainer />}/>
                <Route path="contact" element={<ContactMePage/>}/>
                <Route path="projects" element={<ProjectsContainer/>} />
            </Routes>
        </BrowserRouter>
    )
}