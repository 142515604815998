import React from 'react';
import CanvasElement from '../3dComponents/CanvasElement';





export default function ThreeDCanvasContainer() {

    return (

        <div className='three-canvas-container' style={{width:'100vw',height:'100vh', position:'fixed', top:0, }}>
            <CanvasElement />
        </div> 
    )
    }
                
        


 
    
    