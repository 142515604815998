import { Environment } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import React, {useRef} from 'react';
import FourOFour from './FourOFour';
import { editable as e, PerspectiveCamera, SheetProvider } from '@theatre/r3f';
import { getProject } from '@theatre/core';
import JsonState from '../404Animation.json'
import { useNavigate } from 'react-router-dom';

const notFoundAnimation = getProject('notFound', {state:JsonState}).sheet('pgNotFound');

export default function PageNotFound(){
    const navigate = useNavigate();

    return (
        <div className='not-found-container' style={{ width:'100vw', height:'100vh'}}>
            <div style={{position:'fixed', zIndex:2,  left:'50%', transform:'translateX(-50%)', bottom:'4em', display:'grid', gap:'.5em', width:'100%', justifyContent:'center',}}>
                <h1 style={{fontSize:'1em'}}>Oh no! You've encountered an error.</h1>
                <p style={{color:'white', fontSize:'.9em', padding: 0, textAlign:'center'}}>The page you are looking for is either <br/> unavailable or it doesn't exist.</p>
                <button onClick={()=>navigate('/')} style={{width:'100%'}}>Home</button>
            </div>
            <Canvas style={{backgroundColor:'var(--background-gray)', zIndex:1}}>
                <Environment files='indoorLights.hdr'/>
                <SheetProvider sheet={notFoundAnimation}>
                    <Scene/>
                </SheetProvider>
            </Canvas>
        </div>
    )
}

const Scene = () =>{
    const modelRef = useRef(null)
    useFrame(()=>{
        if(modelRef.current){
            modelRef.current.rotation.y += .014
        }
    })

    return (
        <>
            <PerspectiveCamera makeDefault theatreKey='404cam' position={[3.93, -8.17, 1.17]}/>
            <e.group ref={modelRef} theatreKey='404Content'>
                <FourOFour/>
            </e.group>
        </>
    )

}