import React, {useEffect, useRef, useState} from 'react';
import {db} from '../FirebaseApp';
import { collection, setDoc, doc } from 'firebase/firestore';

export default function ContactForm(){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');
    const messagesCollectionRef= collection(db, 'messagesReceived')
    const nameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const companyInputRef = useRef(null);
    const messageTextareaRef= useRef(null);

    async function sendMessage(e){
        e.preventDefault()
        const docRef= doc(messagesCollectionRef)
        let result = await setDoc(docRef, {
            name,
            email,
            company,
            message,
        })
        clearFields()
    }

    function clearFields() {
        nameInputRef.current.value='';
        emailInputRef.current.value='';
        companyInputRef.current.value='';
        messageTextareaRef.current.value='';
        return
    };



    return (
        <form className='contact-form'>
            <div style={{display:'flex', gap:'.2em'}}>
                <input ref={nameInputRef} onChange={(e)=> setName(e.target.value)} placeholder="Full Name"/>
                <input ref={companyInputRef} onChange={(e) => setCompany(e.target.value)} placeholder="Company"/>
            </div>
            <div>
                <input ref={emailInputRef} onChange={(e)=> setEmail(e.target.value)} placeholder="Email"/>
            </div>
            {/* <div>
             
            </div> */}
            <div>
                <textarea ref={messageTextareaRef} onChange={(e) => setMessage(e.target.value)} placeholder="Message..."/>
            </div>
            <button onClick={(e)=> sendMessage(e)} className="send-button">Send Message</button>
        </form>
    )
}