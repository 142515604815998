import React, { useEffect, useRef, useState } from 'react';
import myPic from '../img/myPicTransparent.png'

export default function PageTwo() {
    const pageTwoContent = useRef(null)
    const [visible, setVisible] = useState(false)
    const containerRef = useRef(null)

    //observe when element intersects viewport.
    const observer = new IntersectionObserver(entries => {
        const { intersectionRatio, isIntersecting, intersectionRect} = entries[0];
        if(intersectionRatio >= .5){
            setVisible(true)
        }
        else setVisible(false)
    }, { threshold: [.1,.5, 1]})


    useEffect(() => {
        if (containerRef.current) {
            observer.observe(containerRef.current)
        }
    })


    return (
        <div
            className={`about-me-container ${visible ? 'visible' : null}`}
            ref={containerRef}
            // style={{width:'100%', height:'100%',border:'.01rem solid var(--border-thin-white)',borderRadius: '.5rem',}}
            style={{
                maxWidth: '100%',
                height: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                overflow: 'hidden',
                position: 'relative',
                border:'.01rem solid var(--border-thin-white)',
                borderRadius: '.5rem',
                opacity:0,
                transition: 'opacity .35s',

            }}>
            <div style={{ padding: '2rem', width: '100%', height: '100%', display: 'grid', alignItems: 'center' }}>
                <h1 className='page-three-title' style={{ color: 'white' }}>Who am I<span className='symbol-green'>?</span></h1>
                <p style={{ fontSize: '.7rem' }}>
                    I am an enthusiastic and inventive Full Stack Developer with a passion for exploring new technologies and pushing the boundaries of what's possible.
                     With an unwavering commitment to learning and a natural curiosity,
                      I thrive on challenges that require me to think outside the box. As a team player,
                       I believe that cooperation and engagement are essential for fostering a dynamic and productive work environment.<br/>
                        <br/>My positive attitude not only drives my personal growth but also inspires my teammates to excel.
                         Eager to embark on an exciting career,
                          I am excited about the endless opportunities ahead and the chance to contribute my skills and creativity to meaningful projects.
                </p>
            </div>
            <div style={{
                width: '100%',
                height: '100%',
                backgroundPosition: 'center',
                borderRadius: '.5rem',
                backgroundSize: 'cover',
                // border:'2px solid red',
                position: 'relative',
                overflow: 'hidden',
                backgroundImage: `url(${myPic})`,
                marginTop: '2rem'
            }}>
            </div>
        </div>
    )
}

