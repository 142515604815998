import React from 'react';
import { Stars as StarsDrei } from '@react-three/drei';

export default function Stars(){

    const options = {
        radius:100,
        depth:50,
        count:5000,
        factor:4,
        saturation:0,
        fade:true,
        speed:1
    }

    return (
        <StarsDrei radius={100} depth={50} count={200} factor={4} saturation={1} fade speed={.7}>
            <meshStandardMaterial color='pink'  metalness={1} roughness={0}/>
        </StarsDrei>
    )
}